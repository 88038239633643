import { CloudInaryFolders } from './../enums/cloudinady-folders.enum';
import { Injectable, inject } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpRestService } from "./http-client/http-rest-client.service";
import { UploadedFileModel } from '../models/submit-payment/uploaded-file.model';
import { RemoveFilesModel } from '../models/submit-payment/remove-files.model';

@Injectable()
export class UploadService extends BaseService {
    private restService = inject(HttpRestService);

    constructor() {
        super();
    }

    public async get(fileId: number): Promise<UploadedFileModel> {
        const result = await this.handleResponse(this.restService.get({
            url: `/upload/get/${fileId}`
        }));

        return result;
    }

    public async upload(file: File, folder: CloudInaryFolders): Promise<number> {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("folder", folder.toString());

        const result = await this.handleResponse(this.restService.upload({
            url: `/upload/save`,
            data: formData
        }));

        return result;
    }

    public async uploadClientFile(file: File, folder: CloudInaryFolders): Promise<UploadedFileModel> {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("folder", folder.toString());

        const result = await this.handleResponse(this.restService.upload({
            url: `/upload/save-client-file`,
            data: formData
        }));

        return result;
    }

    public async uploadAdditionalFiles(file: File, folder: CloudInaryFolders, clientId: number): Promise<UploadedFileModel> {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("folder", folder.toString());
        formData.append("clientId", clientId.toString());

        return await this.handleResponse(this.restService.upload({
            url: `/upload/save-additional-photo`,
            data: formData
        }));
    }

    public async removeFiles(model: RemoveFilesModel): Promise<void> {
        await this.handleResponse(this.restService.post({
            url: `/upload/remove-files`,
            data: model
        }));
    }

}
